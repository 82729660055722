@tailwind base;
@tailwind components;
@tailwind utilities;

/************************************ Base *************************************/

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --chart-1: 173 58% 39%;
    --chart-2: 12 76% 61%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-5: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-2: 340 75% 55%;
  }

  *::-webkit-scrollbar {
    @apply h-2 w-0 bg-gray-200 dark:bg-theme_background;
  }
  *::-webkit-scrollbar-track {
    @apply border border-slate-100 bg-gray-200 dark:bg-theme_background;
  }
  *::-webkit-scrollbar-button {
    display: none;
  }
  *::-webkit-scrollbar-thumb {
    @apply rounded-xl bg-gray-400 dark:bg-gray-600;
  }

  body {
    @apply bg-gray-50 text-sm text-gray-700 dark:bg-theme_background dark:text-gray-300;
  }

  #root {
    @apply overflow-hidden;
  }
  /************************************ Layout *************************************/
  .layout {
    @apply flex h-screen overflow-hidden;
  }

  .layout__content {
    @apply relative flex w-0 flex-1 flex-col overflow-hidden;
  }

  /************************************ Sidebar *************************************/
  .sidebar {
    @apply z-20 hidden border-r border-gray-300 bg-gray-50 dark:border-slate-800 dark:bg-theme_background  md:flex md:flex-shrink-0  dark:md:bg-transparent;
  }

  .nav__link {
    @apply my-3 flex cursor-pointer items-center rounded-md px-2 py-3 leading-5 transition
    duration-150 ease-in-out hover:border-gray-300 hover:bg-gray-100 focus:outline-none dark:text-gray-300 dark:hover:bg-slate-900;
  }
  .nav__link_profile {
    @apply flex cursor-pointer items-center rounded-md px-2 py-3 leading-5 transition
    duration-150 ease-in-out hover:border-gray-300 hover:bg-gray-100 focus:outline-none dark:text-gray-300 dark:hover:bg-slate-900;
  }

  .nav_sub_link {
    @apply my-1 ml-4 flex cursor-pointer  items-center rounded-md px-2 py-3 leading-5 transition
    duration-150 ease-in-out hover:border-gray-300 hover:bg-gray-100 focus:outline-none dark:text-gray-300 dark:hover:bg-slate-900;
  }

  .nav_sub_link.active {
    @apply rounded-md bg-gray-200/75 dark:bg-slate-800 dark:text-gray-200;
  }

  .mobile {
    @apply w-80;
  }

  .nav__link.active {
    @apply rounded-md bg-gray-200/75 dark:bg-slate-800 dark:text-gray-200;
  }

  .nav__link_profile.active {
    @apply rounded-md bg-gray-200/75 dark:bg-slate-800 dark:text-gray-200;
  }

  .logo {
    @apply flex h-16 flex-shrink-0 cursor-pointer items-center border-b border-gray-300 px-4 dark:border-slate-800;
  }

  .side-menu {
    @apply px-2 py-4;
  }

  /************************************ Topbar *************************************/

  .topbar {
    @apply relative z-10 flex h-16 flex-shrink-0 border-b border-gray-300 text-gray-900 dark:border-slate-800;
  }

  .toggle-icon {
    @apply px-4 text-gray-500 focus:outline-none;
  }

  /************************************ Main Content *************************************/

  .main {
    @apply relative flex items-start overflow-hidden focus:outline-none;
  }
  /*
  
  
  /************************************ Global *************************************/
  /* Main Title */
  .title-main {
    @apply border-b border-gray-300 px-4 pb-0.5 text-center font-medium uppercase text-gray-700 dark:border-gray-600 dark:text-gray-400;
  }

  /* Buttons */
  .btn-basic {
    @apply flex w-auto cursor-pointer items-center justify-center rounded-md border border-gray-300 bg-gray-50 p-2 px-3 text-xs 
        outline-none transition duration-150 ease-in-out hover:bg-gray-200 dark:border-gray-700 dark:bg-transparent dark:text-gray-200 dark:hover:bg-gray-700;
  }

  .btn-main {
    @apply inline-flex items-center justify-center rounded-md border bg-teal-400 px-4 py-2 text-center
      text-sm leading-5 text-black ease-out hover:bg-teal-500 focus:border-teal-600 focus:bg-teal-500 focus:outline-none 
      dark:border-gray-700 dark:bg-teal-500 dark:text-black;
  }

  .btn-main-soft {
    @apply inline-flex items-center justify-center rounded-md border border-teal-100 bg-teal-50 px-4 
      py-2 text-center text-sm leading-5 text-teal-700 
      ease-out focus:outline-none dark:border-slate-800 dark:bg-slate-700 dark:text-teal-200;
  }

  .btn-main-outline {
    @apply inline-flex cursor-pointer items-center justify-center rounded-md border border-teal-400 bg-white px-3
      py-1.5 text-center text-sm font-bold leading-5 text-teal-600  
       ease-out hover:text-teal-500 focus:outline-none dark:border-teal-600  dark:bg-slate-900 dark:text-teal-400 dark:hover:text-teal-300;
  }

  .btn-second {
    @apply mb-1 mr-2 inline-block cursor-pointer rounded-lg bg-gray-100 px-3 py-1 text-center text-xs 
     tracking-wider text-teal-800 transition-colors hover:bg-slate-200 dark:bg-slate-700 dark:text-white dark:hover:bg-slate-700;
  }

  .btn-delete {
    @apply mb-1 mr-2 mt-2 inline-flex items-center rounded-md border bg-transparent p-2 px-2 text-center text-lg font-medium leading-5 
      text-red-500 transition-all hover:bg-red-300 hover:text-red-900 focus:border-red-400 dark:border-gray-700;
  }

  .btn-danger {
    @apply mr-2 mt-1 w-24 rounded-md border border-red-300 p-1.5 px-2 text-center
    text-red-900 transition-all hover:bg-red-700 hover:text-white dark:border-red-900  dark:text-red-600 dark:hover:text-white;
  }

  .btn-success {
    @apply mr-2 mt-1 w-24 rounded-md border border-green-400 p-1.5 px-2 text-center
    text-green-900 transition-all hover:bg-green-700 hover:text-white dark:border-green-900 dark:text-green-600 dark:hover:text-white;
  }

  .btn-login {
    @apply flex w-full justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-sm font-medium text-white transition duration-150 ease-out hover:bg-secondary focus:border-blue-600 focus:outline-none active:bg-blue-400;
  }

  /**************************************************************** Forms ****************************************************************/
  .input-main {
    @apply mb-2 mt-2 h-11 w-full rounded-md border bg-transparent p-2 outline-none transition duration-150 ease-in-out 
        focus:!border-indigo-500 focus:!ring-indigo-500 dark:border-gray-700 dark:text-gray-300 sm:text-sm sm:leading-5;
  }

  .input-borderless {
    @apply w-28 cursor-pointer border-b bg-transparent pb-1.5 pl-2 pt-2 text-sm outline-none focus:border-cyan-500 dark:border-gray-700 dark:text-gray-300 focus:dark:border-cyan-800 sm:leading-5;
  }

  select.input-main option {
    @apply dark:bg-gray-700;
  }

  .label-main {
    @apply block text-sm font-medium leading-5 text-gray-500 dark:text-gray-300;
  }

  .label-second {
    @apply text-sm leading-5 text-gray-400 dark:text-gray-500;
  }

  /* Card */
  .card-main {
    @apply flex flex-col overflow-hidden rounded-md border border-gray-300 
    bg-white text-gray-300 shadow transition duration-500 hover:border-teal-500 hover:shadow-md dark:border-slate-800
     dark:bg-slate-900 dark:shadow-gray-800 hover:dark:border-teal-700 dark:hover:shadow-gray-700;
  }

  .card-main-unlink {
    @apply flex flex-col overflow-hidden rounded-md border border-gray-300 
    bg-white text-gray-300 shadow transition duration-500  dark:border-slate-800
     dark:bg-slate-900 dark:shadow-gray-800;
  }

  /************************* Modal **************************/
  .ops-modal {
    @apply invisible fixed left-0 top-0 -z-50 h-full w-full max-w-full overflow-auto opacity-0 transition-all;
  }

  .ops-modal.active {
    @apply visible z-50 opacity-100;
  }

  .ops-modal__content {
    @apply invisible relative mx-auto my-44 w-11/12 max-w-full -translate-y-28 rounded-md border bg-white opacity-0 shadow-lg transition-all dark:border-gray-900 dark:bg-black/95;
  }

  .ops-modal.active .ops-modal__content {
    @apply visible left-32 w-96 translate-y-0 opacity-100;
  }

  .switch {
    @apply relative inline-block h-[34px] w-[60px];
  }

  .switch input {
    @apply h-0 w-0 opacity-0;
  }

  .slider {
    @apply absolute inset-0 cursor-pointer bg-[#ccc] transition-[0.4s];
    -webkit-transition: 0.4s;
  }

  .slider:before {
    @apply absolute bottom-1 left-1 h-[26px] w-[26px] bg-[white] transition-[0.4s] content-[''];
    -webkit-transition: 0.4s;
  }

  input:checked + .slider {
    @apply bg-[#2463e9] before:translate-x-[26px];
  }
  input:focus + .slider {
    @apply shadow-[0_0_1px_#2463e9];
  }

  /* Rounded sliders */
  .slider.round {
    @apply rounded-[34px] before:rounded-[50%];
  }

  .copy-btn {
    @apply rounded-br-md rounded-tr-md bg-secondary px-[18px] py-3 text-white;
  }

  .url-section::-webkit-scrollbar {
    @apply h-1 w-1;
  }
  .url-section::-webkit-scrollbar-thumb {
    @apply bg-secondary;
  }

  .agent-instruction {
    @apply mx-0 my-10 rounded-lg bg-slate-600 text-sm font-normal text-slate-600;
  }
  .agent-instruction-topbar {
    @apply flex w-full select-none justify-between rounded-t-lg bg-gray-700 p-3 pl-4 text-xs font-medium uppercase text-gray-400;
  }
  .agent-instruction-body {
    @apply rounded-bl-lg rounded-br-lg bg-slate-600 pb-2 pt-3;
  }
  .codeBlock-scroll {
    @apply relative flex max-h-[inherit] min-h-[inherit] flex-row overflow-y-auto text-[13px] leading-[19px];
  }
  .codeBlock-lines {
    @apply pointer-events-none flex w-8 shrink-0 grow-0 select-none flex-col gap-[8.5px] whitespace-pre pr-2 text-right tracking-[-1px] text-gray-500;
  }
  .codeBlock-pre {
    @apply m-0 h-full min-h-[16rem] grow overflow-x-auto whitespace-pre px-3 py-0 text-left text-slate-800;
  }
  .language-bash {
    @apply inline-grid gap-2;
  }
  .comment-line {
    @apply text-[#c1c9d2];
  }
  .comment-line-heading {
    @apply flex gap-2 text-slate-50;
  }
  .comment-line-heading.comment-line-margin {
    @apply ml-6;
  }
  .agent-instruction pre.codeBlock-pre::-webkit-scrollbar,
  .agent-instruction pre.codeBlock-pre::-webkit-scrollbar-thumb {
    @apply h-[7px] rounded-lg bg-slate-800 text-transparent;
  }
  .agent-instruction pre.codeBlock-pre::-webkit-scrollbar-track {
    @apply rounded-lg shadow-[inset_0_0_22px_#2a2f45];
  }
  .regenerate-agent {
    @apply mt-8 w-fit cursor-pointer rounded-lg bg-secondary px-3 py-2 text-white hover:bg-blue-700;
  }
  .agent-copy {
    @apply cursor-pointer;
  }
  .agent-instruction-status {
    @apply -mt-0.5 rounded-lg border border-solid border-blue-600 px-2 py-px text-secondary;
  }
  .agent-status {
    @apply flex gap-2 text-sm font-medium uppercase leading-5 text-gray-500;
  }

  .prompt {
    @apply box-border w-full resize-none rounded-md border border-solid p-5 outline-none dark:border-slate-600 dark:bg-slate-700;
  }

  .btn-add {
    @apply flex h-[3.30rem] w-max items-center justify-center rounded-br rounded-tr border-0 bg-secondary px-10 py-1.5 text-center text-sm leading-5 text-white ease-out dark:border dark:border-slate-600 dark:bg-slate-900;
  }

  .category-btn {
    @apply rounded-bl rounded-tl;
  }

  .dropdown-menu {
    @apply left-0 top-16;
  }

  .ops-modal {
    @apply invisible fixed left-0 top-0 -z-50 h-full w-full max-w-full overflow-auto opacity-0 transition-all duration-150 ease-in-out;
  }

  .ops-modal.active {
    @apply visible z-50 opacity-100;
  }

  .ops-modal__content {
    @apply invisible relative mx-auto my-44 w-11/12 max-w-full rounded-md border bg-white opacity-0 transition-all duration-150 ease-in-out;
  }

  .input-borderless {
    @apply w-28 cursor-pointer border-b bg-transparent pb-1.5 pl-2 pt-2 text-sm leading-5 outline-none outline-offset-2;
  }

  .input-secondary {
    @apply my-2 h-10 w-full rounded-md border bg-transparent p-2 outline-none outline-offset-2 duration-150 ease-in-out;
  }

  .input-main {
    @apply my-2 h-11 w-full rounded-md border bg-transparent p-2 outline-none outline-offset-2 duration-150 ease-in-out focus:border-indigo-500;
  }

  .input-app {
    @apply h-11 w-full rounded-md border bg-white p-2 outline-none outline-offset-2 duration-150 ease-in-out focus:border-indigo-500;
  }

  .input-main-disabled {
    @apply my-2 h-11 w-full cursor-default rounded-md border bg-gray-100 p-2 outline-none outline-offset-2 duration-150 ease-in-out dark:border-slate-800 dark:bg-slate-700;
  }

  .btn-success {
    @apply mr-2 mt-1 w-24 rounded-md border border-green-400 p-1.5 px-2 text-center text-green-700 transition-all duration-150 ease-in-out hover:bg-green-700 hover:text-white dark:border-green-900 dark:text-green-600 dark:hover:text-white;
  }

  .btn-blue {
    @apply mr-2 mt-1 w-24 rounded-md border border-blue-600 bg-secondary p-1.5 px-2 text-center text-white transition-all duration-150 ease-in-out hover:bg-blue-700 hover:text-white dark:border-blue-900 dark:text-secondary dark:hover:text-white;
  }

  .btn-danger {
    @apply mr-2 mt-1 w-24 rounded-md border border-red-300 p-1.5 px-2 text-center text-red-900 transition-all duration-150 ease-in-out hover:bg-red-700 hover:text-white dark:border-red-900 dark:text-red-600 dark:hover:text-white;
  }

  .btn-delete-second {
    @apply mb-2 mr-2 mt-7 inline-flex items-center rounded-md border bg-transparent p-2 text-center text-lg font-medium leading-5 text-red-500 transition-all duration-150 ease-in-out hover:bg-red-300 hover:text-red-900 focus:border-rose-500 dark:border-gray-700;
  }

  .btn-delete {
    @apply my-2 mr-2 inline-flex items-center rounded-md border bg-transparent p-2 text-center text-lg font-medium leading-5 text-red-500 transition-all duration-150 ease-in-out hover:bg-amber-500 hover:text-red-900 focus:border-rose-500 dark:border-gray-700;
  }

  .btn-second {
    @apply mb-1 mr-2 inline-block cursor-pointer rounded-lg bg-gray-100 px-3 py-1 text-center text-xs leading-4 tracking-wider text-teal-800 duration-150 ease-in-out hover:bg-slate-200 dark:bg-slate-700 dark:text-white dark:hover:bg-slate-700;
  }

  .btn-main-outline {
    @apply inline-flex cursor-pointer items-center justify-center rounded-md border border-blue-400 bg-white px-3 py-1.5 text-center text-sm font-bold leading-5 text-secondary ease-out hover:text-blue-500 dark:border-blue-600 dark:bg-slate-900 dark:text-blue-400 dark:hover:text-blue-300;
  }

  .user-role {
    @apply mt-3 w-fit rounded-md bg-sky-100 px-1.5 py-0.5 text-secondary;
  }

  .btn-main-light {
    @apply inline-flex items-center justify-center rounded-md border border-teal-100 bg-teal-50 px-2 py-[0.2rem] text-center text-[0.675rem] leading-3 text-teal-700 ease-out;
  }

  .btn-main {
    @apply inline-flex items-center justify-center rounded-md border bg-sky-400 px-4 py-2 text-center text-sm leading-5 text-sky-900 ease-out hover:bg-sky-500 focus:border-sky-900 focus:bg-sky-500 dark:border-sky-300 dark:bg-sky-300 dark:text-black dark:hover:bg-sky-500 dark:focus:border-sky-600 dark:focus:bg-slate-400;
  }

  .btn-basic {
    @apply flex w-auto cursor-pointer items-center justify-center rounded-md border border-gray-300 bg-gray-50 p-2 px-3 text-xs leading-4 outline-none outline-offset-2 duration-150 ease-in-out hover:bg-gray-200 dark:border-gray-700 dark:bg-transparent dark:text-gray-200 dark:hover:bg-gray-700;
  }

  .nav__link_submenu {
    @apply my-3 flex items-center rounded-md px-6 py-0 text-sm font-medium leading-5 text-gray-900 duration-150 ease-in-out;
  }

  .sr-only {
    @apply absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0;
  }

  .form-container {
    @apply mx-auto w-full;
  }

  .project-card {
    @apply m-[0.5em] cursor-pointer border border-solid duration-150 ease-out hover:border hover:border-slate-300 hover:shadow-xl;
  }
  .addnew-btn {
    @apply right-0 w-max text-left;
  }

  .line-clamp-3 {
    @apply overflow-hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  .line-clamp-1 {
    @apply overflow-hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  .tooltip {
    @apply invisible absolute;
  }

  .has-tooltip {
    @apply relative;
  }

  .upgrade-plan {
    @apply absolute -top-1 right-4 animate-pulse rounded-lg bg-secondary px-1.5 py-1 text-sm text-white;
  }

  .upgrade-plan-lightless {
    @apply absolute -top-1 right-4 rounded-lg bg-secondary px-1.5 py-1 text-sm text-white;
  }

  .trigger-card {
    @apply m-[0.5em] w-52 cursor-pointer overflow-hidden rounded-lg border bg-white text-gray-800 shadow-lg transition duration-150 ease-out hover:border hover:border-slate-300 hover:shadow-xl dark:border-slate-800 dark:bg-slate-800 dark:text-gray-200 dark:shadow-none dark:shadow-gray-800 hover:dark:border-teal-700 dark:hover:shadow-gray-700;
  }

  .grayscale-btn {
    @apply m-[0.5em] w-52  overflow-hidden rounded-lg border bg-gray-100 text-gray-400 shadow-lg grayscale transition  dark:border-slate-800 dark:bg-slate-800 dark:text-gray-200 dark:shadow-none dark:shadow-gray-800;
  }

  .connection-grayscale {
    @apply m-[0.5em]  overflow-hidden rounded-lg border bg-gray-100 text-gray-400 shadow-lg grayscale transition  dark:border-slate-800 dark:bg-slate-800 dark:text-gray-200 dark:shadow-none dark:shadow-gray-800;
  }

  .multi-select-input {
    @apply !bg-transparent dark:border-gray-700 dark:bg-transparent hover:dark:border-gray-700;
  }
  .multi-select-input-disabled {
    @apply !bg-gray-100 dark:border-gray-700 dark:bg-transparent hover:dark:border-gray-700;
  }

  .multi-select-input-focus {
    @apply !bg-transparent dark:border-gray-700 dark:bg-transparent hover:dark:border-gray-700;
  }

  .multi-select-default-value {
    @apply items-center gap-2 rounded-lg !bg-secondary !text-white dark:!bg-slate-700 dark:text-gray-200;
  }

  .multi-select-value-label {
    @apply !text-white dark:!text-gray-200;
  }

  .multi-select-value-remove {
    @apply h-5  bg-secondary text-gray-100  hover:border-red-300 hover:bg-red-50 hover:text-red-800 dark:bg-transparent;
  }

  .multi-select-dropdown {
    @apply !rounded-sm !bg-white dark:rounded-sm dark:!bg-slate-700 dark:!text-gray-200;
  }

  .multi-select-option {
    @apply dark:!bg-slate-700 dark:!text-gray-200 hover:dark:!bg-slate-900;
  }

  .multi-select-input-text {
    @apply dark:!text-gray-200;
  }

  .multi-select-input-text::placeholder {
    @apply dark:!text-gray-200;
  }

  .cancel-button {
    @apply rounded-md bg-red-600 px-4 py-2 text-white hover:bg-red-700;
  }

  .save-button {
    @apply rounded-md bg-green-600 px-8 py-2 text-white hover:bg-green-700;
  }

  .custom-select {
    @apply !bg-white dark:!border-slate-600 dark:!bg-transparent;
  }

  .custom-select-input {
    @apply dark:!text-gray-200;
  }

  .approver-group-dropdown {
    @apply !rounded-sm !bg-white dark:rounded-sm dark:!bg-slate-700 dark:!text-gray-200;
  }

  .approver-group-option {
    @apply dark:!bg-slate-700 dark:!text-gray-200 hover:dark:!bg-slate-900;
  }

  .search-all {
    @apply flex items-center justify-center rounded-l-md border border-gray-300 px-6 py-4 uppercase dark:border-slate-600;
  }

  .search-input {
    @apply z-20  block w-full border border-l-2 border-gray-300 border-l-gray-50 bg-white px-6 py-4 text-sm text-gray-900 outline-none dark:border-slate-600 dark:bg-slate-600 dark:text-gray-200;
  }

  .command-card {
    @apply flex flex-1 items-center justify-between rounded-r-md border-b border-r border-t border-gray-200 bg-white transition duration-500 hover:shadow-md dark:border-slate-600 dark:bg-slate-900 dark:shadow-gray-800 hover:dark:border-teal-700 dark:hover:shadow-gray-700;
  }

  .integration-card {
    @apply h-44 w-52 overflow-hidden rounded-lg bg-white text-gray-800 shadow-md transition duration-500 hover:shadow-lg dark:border-slate-600 dark:bg-slate-900 dark:shadow-gray-800 hover:dark:border-teal-700;
  }

  .integration-card-disable {
    @apply h-44 w-52 overflow-hidden rounded-lg bg-white text-gray-800 opacity-40 shadow-md transition duration-500 hover:shadow-lg dark:border-slate-600 dark:bg-slate-900 dark:shadow-gray-800 hover:dark:border-teal-700;
  }

  .command-arguments {
    @apply relative flex items-center gap-3 pb-4 pr-12;
  }

  .hero-section {
    @apply mx-auto my-2 max-w-4xl overflow-auto py-2;
  }

  .help-container {
    @apply flex flex-col gap-6;
  }

  .help-message {
    @apply flex items-center gap-2;
  }

  .help-description {
    @apply text-[15px] leading-6;
  }

  .password-required {
    @apply my-2 grid grid-cols-2 gap-2;
  }

  .password-required-content {
    @apply flex items-center gap-2 text-gray-400;
  }

  @layer components {
    .react-select-base-container .react-select-base__control {
      @apply dark:border-gray-600 dark:bg-gray-800;
    }

    .react-select-base-container .react-select-base__control--is-focused {
      @apply dark:bg-gray-700;
    }

    .react-select-base-container .react-select-base__menu {
      @apply dark:bg-gray-800;
    }

    .react-select-base-container .react-select-base__option {
      @apply dark:bg-gray-700 dark:text-neutral-200 dark:hover:bg-neutral-800;
    }

    .react-select-base-container .react-select-base__indicator-separator {
      @apply bg-neutral-400;
    }

    .react-select-base-container .react-select-base__input-container,
    .react-select-base-container .react-select-base__placeholder,
    .react-select-base-container .react-select-base__single-value {
      @apply text-neutral-600 dark:text-neutral-200;
    }
  }
  .eventlog-description {
    @apply text-[13px] font-bold;
  }

  span.flatpickr-day {
    border: none !important;
  }

  span.flatpickr-day.startRange:hover {
    border: 1px solid rgba(12, 102, 228, 0.15) !important;
    border-radius: 50px !important;
    background-color: none !important;
  }

  span.flatpickr-day.endRange:hover {
    border-radius: 50px !important;
    background: #224069 !important;
  }
  .flatpickr-day.selected {
    border-radius: 50px !important;
    background: #224069 !important;
  }

  span.flatpickr-day.selected.startRange {
    border-radius: 50px !important;
    background: #224069 !important;
  }

  span.flatpickr-day.selected.endRange {
    border-radius: 50px !important;
    background: #224069 !important;
  }

  .info-box {
  }

  .ͼ2 .cm-activeLine {
    @apply bg-transparent;
  }

  .ͼ2 .cm-activeLineGutter {
    @apply bg-transparent;
  }

  .ͼ1 .cm-content {
    @apply whitespace-normal;
  }

  .crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf,
  .crisp-client .cc-1brb6 .cc-1yy0g .cc-1no03 {
    right: 1rem !important;
    bottom: 3.5rem !important;
    width: min-content !important;
  }

  :root {
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
.recharts-legend-wrapper ul.recharts-default-legend {
  @apply !p-1.5;
}
li.recharts-legend-item.legend-item-0 {
  @apply p-1.5;
}
.cm-editor{
  @apply !rounded-md;
}
.cm-scroller {
  @apply !rounded-md;
}
.ͼ14 {
  @apply !bg-[#000f29];
}
